import { Controller } from "@hotwired/stimulus"

const disabledTagName = [
  "FIELDSET",
  "INPUT",
  "SELECT",
  "TEXTAREA"
]

export default class ToggleExpandController extends Controller {
  static targets = ["toggle"]

  toggle(event) {
    if (this.revertTimeoutId) clearTimeout(this.revertTimeoutId)

    // Update aria-expanded on trigger element
    //
    const trigger = event.currentTarget
    if (trigger.hasAttribute("aria-expanded")) {
      if (trigger.ariaExpanded == "true") {
        trigger.setAttribute("aria-expanded", "false")
      } else {
        trigger.setAttribute("aria-expanded", "true")
      }
    }

    // Toggle targets:
    //    * togggle the hidden attribute by default
    //    * togggle the disabled attribute on fieldsets, inputs, etc...
    //
    this.toggleTargets.forEach((item) => {
      item.toggleAttribute("hidden")

      if (disabledTagName.includes(item.tagName)) {
        item.toggleAttribute("disabled")
      }
    })

    // Allow to revert after timeout passed as event param.
    // If a previous timeout exist, just delete it
    //
    if (this.revertTimeoutId) {
      this.revertTimeoutId = null
    } else if (event.params.revertDelay) {
      this.revertTimeoutId = setTimeout(
        this.toggle.bind(this, { currentTarget: trigger }),
        event.params.revertDelay
      )
    }
  }
}
