import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class NotificationController extends Controller {
  static values = { delay: Number }

  connect () {
    // Because of turbo morphing, the controller may be re-connected after each
    // page loads or page refresh. We must avoid to start again transition & timeout.
    if (this.connectedAt) return

    // Add hidden class to force transition animation.
    this.element.classList.add("hidden")

    useTransition(this)
    this.enter()

    this.connectedAt = new Date()
    this.startTimeout(this.delayValue)
  }

  startTimeout (delay) {
    this.stopTimeout()
    if (delay) this.timeout = setTimeout(this.hide.bind(this), delay)
  }

  stopTimeout () {
    if (this.timeout) clearTimeout(this.timeout)
  }

  resumeTimeout () {
    if (!this.delayValue || !this.connectedAt) return

    // Calculate the elasped time since we shown the notification
    const now     = new Date()
    const elasped = now - this.connectedAt

    // Keep the notification 2 seconds on screen, unless it remains more than 3 seconds
    // of the original delay.
    //
    let newDelay = this.delayValue - elasped
    if (newDelay < 2000) newDelay = 2000

    this.startTimeout(newDelay)
  }

  async hide (event) {
    if (event) event.preventDefault()
    this.stopTimeout()

    await this.leave()
    this.element.remove()
  }
}
